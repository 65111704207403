.left_arrow{
  position: absolute;
  top: 50%;
  left: 16px;
  opacity: 0.8;
  border-radius: 32px;
  font-size: 40px;
  padding: 0;
  background-color: #000000;
  color: white;
  transition: all 0.1s ease-in-out;
}
.left_arrow:hover{
  background-color: rgb(24, 23, 23);
}
.videos{
  margin: 0;
  
  padding: 0;
  transition: all 0.5s ease-in-out;
  
  text-align:center;

  position: relative;
  overflow: hidden;

}

.video{
  width: 45%;
  height: auto;
  top:50%;
  margin-top:3%;
  transition: all 0.8s ease-in-out;
  align-self: center;
    border-radius: 20px;
}
.right_arrow{
  position: absolute;
  top: 50%;
  right: 16px;
  border-radius: 32px;
  font-size: 40px;
  padding: 0;
  opacity: 0.8;

  background-color: #000000;
  color: white;
  transition: all 0.1s ease-in-out;
}
.right_arrow:hover{
  background-color: rgb(24, 23, 23);
}