.gpt3__navbar{
    display:flex;
    justify-content: flex-start;
    align-items: center;
   
    margin-top: 0;
    padding-top: 0;
  
  left: 0;
  right: 0;
  z-index: 110;
    background-color: #a5ebbaf7;
    transition: all 0.5s ease-in-out;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0,0.5);
    
}
.navigacija{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: row;
    transition: all 0.5s ease-in-out;
    z-index: 999;
    position: sticky;
}
ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.gpt3__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items:center;
    margin: 0;
    padding: 0;

}

.logo-dugme{
    background-color: transparent;
    border: transparent;
    z-index: 100;
}

.gpt3__navbar-links_logo img {
    
    width: 110px;
    height: 100px;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    padding: 0;
    align-items: flex-start;
}
.gpt3__navbar-links_logo{
    margin-right: 45px;
    top: 20px;
    justify-content: center;
    
    margin-left: 2rem;
    background-color: transparent;
    border: transparent;
}

.gpt3__navbar-links_container {
    margin-left: 22%;
    display: sticky;
    justify-content: center;
    text-align: center;
    transition: all 0.5s ease-in-out;
    flex-direction: row;
	margin-top:40px;
   
}


.viogi{
    display: flex;
    transition: all ease-in-out;
    font-size: 25px;
    margin: 0;
    margin-left: -9px;
    color: #006F61;
    font-weight: bold;
    font-family: var(--font-family);
   
    /* -webkit-text-stroke-width: 1px; */
    -webkit-text-stroke-color: rgba(97, 97,97, 0.4);
    flex-direction: column;
    padding: 0;
    
}
.back{
    background-color: #006F61;
    height: 40px;
    width: 100%;
    position: flex;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 998;
}

.gpt3__navbar-menu_container{
    display: flex;
    justify-content: right;
	width:100px;
    align-items: flex-end;
    flex-direction: column;
    transition: all 0.5s ease-in-out;
	position:absolute;
    text-align: end;

    margin-left:auto;
	margin-right:0;
	/*top:-100%;*/
    background-color: #a5ebbaf7; ;
    top:20%;
    position: absolute;

    right: 0;
    margin-top: 0.08rem;
    min-width: 180px;
    min-height: 100%;
    align-items: center;
    
        /* Add a transition property to the menu container */

      
    
    /* border-radius: 5px; */
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.1);
}
.gpt3__navbar-menu_open+gpt3__navbar-menu_container{
	margin-top:0;
}


.gpt3__navbar-links_container p,
.gpt3__navbar-menu_container p{
    color: #152118;
    
    font-family: var(--font-family);
    font-weight: bold ;
    font-size: 26px;
    
    height: 100%;
    transition: all 0.15s ease-in-out;
   
    text-decoration: none;
    text-align: center;
    padding: 10px;
    padding-right: 30px;
    padding-left: 30px;
    line-height: 40px;
    
    text-transform: capitalize;
    
    cursor: pointer;
    outline: none;
    border: 0;
    position: relative; /* add this */
}

.gpt3__navbar-links_container p:hover,
.gpt3__navbar-menu_container p:hover{
    position: relative; /* needed for the pseudo-element */
  text-decoration: none;
  
}
.gpt3__navbar-links_container p:before,
.gpt3__navbar-menu_container p:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 4%; /* add this */
    border-bottom: 5px solid #7FC795;
    border-radius: 4px;
    transition: width 0.35s ease-in-out; /* change this */
    
    z-index: -10;
}

.gpt3__navbar-links_container p:hover:before,
.gpt3__navbar-menu_container p:hover:before {
  width: 60%; /* change this */
}
.hamburger-menu-button{
    background-color: transparent;
    border: transparent;
    margin: 0;
    height: 20px;
    width: 20px;
}

.gpt3__navbar-menu {
  /* other styles */
  
  background-color: transparent;
  display: none;
 
}


.gpt3__navbar-menu svg{
    cursor: pointer;
}




  


@media screen and (max-width:1050px) {

    .gpt3__navbar-links_logo img {
        width: 52px;
        height: 42px;
    }

    .gpt3__navbar-links_container{
        display: none;
        transition: all 0.5s ease-in-out;
        

    }

    .gpt3__navbar-menu{
        display: flex;
        flex-direction: column;
        transition: all 0.5s ease-in-out;
        
    }
    .gpt3__navbar-menu_container{
        transition: all 0.5s ease-in-out;
        display: flex;
        flex-direction: column;
        min-height: 0;
        z-index: 1000;
        border-radius: 5%;

        
    }
    .navigacija{
        transition: all 0.5s ease-in-out;
        display: flex;
        flex-direction: column;  
    }
    .gpt3__navbar{
        padding: 0.8rem 1.7rem;
    }
    .viogi{
        

        /* margin-left: 10px; */
        size: 20px;
    }
    
}

@media screen and (max-width:550px) {
    .gpt3__navbar{
        padding: 0.8rem 1rem;
    }
    .gpt3__navbar-menu_container{
        /* ff 3.6+ */
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        
        background-color: #7FC795 
        
    }

}


