

.features{
    
    /* height: 900px; */
 
}
.cards-items:hover{
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.4);
    
}
.cards-items:hover .card-title{
    color: var(--color-text);
}

.cards{
    margin-bottom: 25px;
    justify-content: center;
    display: flex;
    flex-direction: row;
}
.icons{
    margin: 10px;
    margin-top: 40px;
    margin-left: 40px;
    width: 50px;
    height: 50px;
    transition: all 0.35s ease-in-out ;
    color: var(--color-text);
    background-color: transparent;
    
}
.icons:hover{
    padding-bottom: 5px;
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.02);
}
.naslov-kartica{
    text-align: left;
    text-justify:distribute;
    letter-spacing: 2px;
    text-decoration: none;
    color: var(--color-text);
    font-family: var(--font-family);
    justify-self: center;
    padding-top: 80px;
    margin-left: 20%;
    margin-bottom: 40px;
    font-size: 40px;
}
.cards-items{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    margin-left: 70px;
    margin-bottom: 25px;
    width: 350px;
    height: 350px;
    transition: all 0.5s ease-in-out ;
    background-color: aliceblue;
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    
}
.card-content{
    font-size: 15px;
    font-family: var(--font-family);
    justify-content: stretch;
    margin-top: 30px;
    color: var(--color-blog);
    margin-left: 30px;
    line-height: 30px;
    letter-spacing: 1.5px;
    margin-right: 10px;
}
.card-title{
    font-size: 30px;
    margin-top: 30px;
    margin-left: 40px;
    transition: all 0.5s ease-in-out ;
    color: var(--color-blog);
    font-family: var(--font-family);
}
.cardlist{
    padding-bottom: 20px;
    align-items: center;
    justify-content: center;
}
.opis{
    
    background-color: rgba(240, 248, 255,80);
    transition: all 0.5s ease-in-out ;
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width:1050px) {

    .cardslist{
        display: flex;
        flex-direction: column;

    }
    div.cards{
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        align-content: center;
        align-items: center;

    }
    .icons{
        transition: all 0.5s ease-in-out;
        display: flex;
        margin-top: 0;
        flex-direction: column; 
    }
    .cards-items{
        margin-left: 0%;
        height: 350px;
        width: 350px;

    }
    
}

@media screen and (max-width:550px) {
    .gpt3__navbar{
        padding: 0.8rem 1rem;
    }
    .gpt3__navbar-menu_container{
        /* ff 3.6+ */
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        
        background-color: #7FC795 
        
    }

}

