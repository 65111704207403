.podnaslov_1{
    padding-top: 20px;
    font-family: var(--font-family);
}
.pasus_pravneusluge{
    color: #333E44;
    font-family: var(--font-family);
    border: 0;
    font-size: 19px;
    font-style: inherit;
    font-weight: 400;
    margin: 0;
    margin-bottom: 0px;
    outline: 0;
    padding: 0;
    width: 528px;
    line-height: 32px;
}