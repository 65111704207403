*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    /* overflow-x: hidden; */
}

body{
    background-image: url("./assets/bck.png");
   
    background-attachment: fixed;
    background-size: cover;
    /* max-height: 2000px; */
}

a{
    color: unset;
    text-decoration: none;
}

/* .gradient__bg         


{

   
    background-color: #7FC795 ;
    

}
             */
        
.gradient__text{
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}            
            
/* .section__padding{
    padding: 4rem 6rem;
}

.section__margin{
    margin: 4rem 6rem;
} */

.shadow-drop-2-center {
	-webkit-animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes shadow-drop-2-center {
    0% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-transform: translateZ(50px);
              transform: translateZ(50px);
      -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
              box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }
@keyframes shadow-drop-2-center {
    0% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-transform: translateZ(50px);
              transform: translateZ(50px);
      -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
              box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }
  
  @media screen and (max-width:1050px){
   *{
    overflow-x: hidden;
   }
  }
 
  @media screen and (max-width:550px){
    *{
      overflow-x: hidden;
     }
  }
 
       
         
