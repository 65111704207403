.kontakt_naslov{
    color: #006F61;
    font-family: "Roboto Slab", Sans-serif;
    font-size: 4.9em;
    text-align: left;
    font-weight: 800;
    padding-bottom: 2%;
    font-family: "Roboto Slab", Sans-serif;
}

.naslov_podnaslov_kontakt{
    position: relative;
    
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 300px;
    font-family: "Roboto Slab", Sans-serif;
}
.podnaslov_kontakt{

    font-family: "Roboto Slab", Sans-serif;
    text-align: left;
    color: #333e44;
    padding-bottom: 5%;
    font-size: 2em;
    font-weight: 400;
    text-decoration: none;
    line-height: 1.3em;
    letter-spacing: 0.3px;
    
}
.t_slika{
	width:300px;
	height:300px;
}
.kagencija{
    padding-left: 300px;
    color: #333e44;
    font-family: "Roboto Slab", Sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.5px;
}
.kagencija_podnaslov{
    padding-left: 300px;
    color: #333e44;
    font-family: "Roboto Slab", Sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.5px;
}

.tabela_kontakt{
    padding-left: 300px;
    padding-top: 5%;
    border: none;
    padding-bottom: 15%;
}
.tabela_polja_kontakt{
    color: #333e44;
    padding-top: 3%;
    font-size: 20px;
    font-family: "Roboto Slab", Sans-serif;
}
.tabela_polja_kontakt_he{
    color: #006F61;
    padding-top: 3%;
    font-size: 20px;
    font-family: "Roboto Slab", Sans-serif;
    font-weight: 600;
}


@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

    .formcarry-container {
      box-sizing: border-box;
      margin-left: 300px;
      padding: 0;
      padding-top: 2%;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.01em;
      padding-bottom: 10%;
      width: 900px;

      /* NORMAL */
      --fc-border-color: #ECEFF9;
      --fc-normal-text-color: #00b994;
      --fc-normal-placeholder-color: #B3B8D0;

      /* PRIMARY COLOR | HSL FORMAT*/
      --fc-primary-color-hue: 220;
      --fc-error-color-hue: 356;
      --fc-primary-hsl: var(--fc-primary-color-hue), 100%, 54%;
      --fc-error-hsl: var(--fc-error-color-hue), 100%, 54%;

      /* HOVER */
      --fc-field-hover-bg-color: #F7F9FC;
      --fc-border-hover-color: #DDE0EE;
      --fc-field-hover-text-color: #B3B8D0;

      --fc-border-active-color: #006F61;
    }

    .formcarry-container * {
      box-sizing: border-box;
    }

    .formcarry-container label {
      display: block;
      cursor: poRoboto;
    }

    .formcarry-container .formcarry-block:not(:first-child) {
      margin-top: 16px;
      width: 900px;
    }
    
    
    /*=============================================
    =            Fields           =
    =============================================*/
    
    .formcarry-container input,
    .formcarry-container textarea,
    .formcarry-container select {
      margin-top: 4px;
      width: 100%;
      height: 42px;
      border: 1px solid var(--fc-border-color);
      color: var(--fc-normal-text-color);
      border-radius: 6px;
      padding: 8px 12px;
      
      font-family: "Roboto", sans-serif;
      font-size:14px;
      transition: 500ms background, 500ms color, 500ms box-shadow;
    }

    .formcarry-container textarea{
      min-height: 188px;
      max-width: 100%;
      padding-top: 12px;
    }

    .formcarry-container input::placeholder,
    .formcarry-container textarea::placeholder,
    .formcarry-container select::placeholder {
      color: var(--fc-normal-placeholder-color);
    }

    .formcarry-container input:hover,
    .formcarry-container textarea:hover,
    .formcarry-container select:hover {
      border-color: var(--fc-border-hover-color);
      background-color: var(--fc-field-hover-bg-color);
    }

    .formcarry-container input:hover::placeholder,
    .formcarry-container textarea:hover::placeholder,
    .formcarry-container select:hover::placeholder {
      color: var(--fc-field-hover-text-color);
    }

    .formcarry-container input:focus,
    .formcarry-container textarea:focus,
    .formcarry-container select:focus {
      background-color: #fff;
      border-color: hsl(var(--fc-primary-hsl));
      box-shadow: hsla(var(--fc-primary-hsl), 8%) 0px 0px 0px 3px;
      outline: none;
    }

    .formcarry-container select {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9997 14.5001L8.46387 10.9642L9.64303 9.78589L11.9997 12.1434L14.3564 9.78589L15.5355 10.9642L11.9997 14.5001Z' fill='%236C6F93'/%3E%3C/svg%3E%0A");
      /* background-position: calc(100% - 20px) calc(1em + 4px), calc(100% - 15px) calc(1em + 4px); */
      background-size: 24px 24px;
      background-position: 98%;
      background-repeat: no-repeat;
      appearance: none;
      -webkit-appearance: none;
    }

    .formcarry-container button {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: -0.02em;
      height: 42px;
      line-height: 40px;
      width: 100%;
      border-radius: 6px;
      box-sizing: border-box;
      border: 1px solid hsla(var(--fc-primary-hsl));
      background-color: #006F61;
      color: #fff;
      cursor: poRoboto;
    }

    .formcarry-container button {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: -0.02em;
      height: 40px;
      line-height: 24px;
      width: 100%;
      border: 0;
      border-radius: 6px;
      box-sizing: border-box;
      
      color: #fff;
      cursor: poRoboto;
      box-shadow: 0 0 0 0 transparent;
      
      transition: 500ms all;
    }
    
    .formcarry-container button:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #006F61;
    }
    
    .formcarry-container button:focus {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #006F61;
      border-inline: 1px solid inline rgba(255, 255, 255, 0.6);
      box-shadow: 0px 0px 0px 3px rgba(var(--fc-primary-hsl), 12%);
    }

    .formcarry-container button:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #006F61;
    }

    .formcarry-container button:disabled {
      background-color: hsla(var(-#006F61), 40%);
      cursor: not-allowed;
    }

    .formcarry-container input:focus:required:invalid,
    .formcarry-container input:focus:invalid, 
    .formcarry-container select:focus:required:invalid,
    .formcarry-container select:focus:invalid
    {
      color: hsl(var(--fc-error-hsl)); 
      border-color: hsl(var(--fc-error-hsl));
      box-shadow: 0px 0px 0px 3px hsla(var(--fc-error-hsl), 12%);
    }
.google_mapa{
    width: 100%;
    height: 100%;
}