.Parentclass {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

.footer {
/* Your footer styles */
/* For example: */
  /*  background-color: rgba(255, 255, 255, 0.719);
*/ 
padding: 0%;
margin: 0%;
margin-top: 80%; /* Pushes the footer to the bottom */
}
.cards-items-usluge:hover{
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.4);
    
}
.cards-items-usluge:hover .card-title{
    color: var(--color-text);
}
.cards{
    margin-bottom: 25px;
    justify-content: center;
    display: flex;
    flex-direction: row;
}
.cards-items-usluge{
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    margin-right: 70px;
    width: 380px;
    height: 520px;
    transition: all 0.5s ease-in-out ;
    background-color: aliceblue;
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.2);
    
}
.card-content-usluga{
    color: #333E44;
    padding: 0 20px 20px 20px;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 23px;
    font-family: var(--font-family);
    
}


.card-title-usluga{
    font-size: 30px;
    margin-top: 10px;
    margin-left: 20px;
    transition: all 0.5s ease-in-out ;
    color: var(--color-blog);
    font-family: var(--font-family);
}
.cardlist{
    padding-bottom: 20px;
}
.naslov_usluga{
    font-size: 4.9rem;
    color: #006F61;
    text-align: left;
    padding-bottom: 30px;
    font-family: var(--font-family);
}
.opis_usluga{
    text-align: left;
    color: #333e44;
    font-size: 2em;
    text-decoration: none;
    font-family: var(--font-family);
    line-height: 1.3em;
    letter-spacing: 0.3px;
    
    
}
.naslov_i_opis_usluga{
    padding: 0% 5% 0% 10%;
    padding-bottom: 110px;
}
.kartice_usluga{
    position: absolute;
    z-index: 10;
    
}
.slike_za_kartice{
    width: 100%;
    cursor:pointer;
}
.usluge{
      /*  background-color: rgba(255, 255, 255, 0.719);
*/ 
    /* height: 900px; */
    
    padding-bottom: 10%;
}
         
