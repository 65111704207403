.o_nama-container{
  /*  background-color: rgba(255, 255, 255, 0.719);
*/ 
    align-items: center;
    justify-content: center;
    align-content: center;
}
.container_onama{
    padding: 0% 5% 0% 20%;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: flex;
    /* flex-direction: row; */
    justify-items: center;
    padding-bottom: 10%;
}
.lista_onama {
    list-style-type:circle;
    padding-left: -10px;
    /* word-wrap: break-word;
    white-space: nowrap; */
  }

.delovi_liste_onama{
    color: #333E44;
    border: 0;
    font-size: 19px;
    font-style: inherit;
    font-weight: 400;
    margin: 0;
    outline: 0;
    padding: 0;
    margin: 0 0 1em 2em;
    letter-spacing: 0.3px;
    line-height: 24px;
    width: 480px;
    font-family: var(--font-family); /* Prevents text wrapping */
    overflow: hidden; /* Hides the overflow */
    text-overflow: ellipsis; 
}
.resenje{
    color: #259b43;
    text-decoration: underline;
}
.resenje:hover{
    cursor: pointer;
}
@media screen and (max-width: 768px) {
  .container_onama {
    /* Adjust padding or layout for smaller screens */
    padding: 5% 5% 10% 5%; /* Example padding adjustment */
  }

  .delovi_liste_onama {
    /* Adjust font size or other styles for smaller screens */
    font-size: 18px; /* Example font size adjustment */
    width: 100%; /* Adjust width for smaller screens */
    margin: 0 0 1rem 0.5rem; /* Adjust spacing for smaller screens */
    /* Add other necessary styles */
  }
  .podnaslov_1_onama{
    width: 100%;
  }
}


.lista {
    list-style-type:disc;
    padding-left: 30px;
  }
.naslov_os{
    font-size: 4.9rem;
    color: #006F61;
    font-family: var(--font-family);
}
.pasus_1_onama{
    color: #333E44;
    border: 0;
    font-size: 19px;
    font-style: inherit;
    font-weight: 400;
    margin: 0;
    margin-bottom: 0px;
    outline: 0;
    overflow-wrap: break-word; /* For modern browsers */
    word-wrap: break-word; /* For older browser support */
    word-break: break-word;
    padding: 0;

    font-family: var(--font-family);
    line-height: 24px;
}
.delovi_liste{
    color: #333E44;
    border: 0;
    font-size: 19px;
    font-style: inherit;
    font-weight: 400;
    margin: 0;
    outline: 0;
    padding: 0;
    margin: 0 0 1.2em 3em;
    overflow: hidden; /* Hides the overflow */
    text-overflow: ellipsis; 
    letter-spacing: 0.3px;
    line-height: 24px;
    width: 480px;
    font-family: var(--font-family);
}
.podnaslov_1_onama{
    color: #333E44;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.5px;
    font-family: var(--font-family);
    overflow-wrap: break-word; /* For modern browsers */
    word-wrap: break-word; /* For older browser support */
    word-break: break-word;
    
}
.linija{
    border: 0;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
    --divider-border-style: solid;
    --divider-color: #DDDDDD;
    --divider-border-width: 1px;
    padding-bottom: 20px;
}
.podnaslov{
    color: #333E44;
    font-size: 19px;
    font-family: var(--font-family);
}
.onm{
    position: relative;
    top: 18%;
    right: 15%;
    flex-direction: column;
    align-self: center;
    border-radius: 50%;
    box-shadow: #333E44;
    box-sizing: border-box;
    max-width: 300px;
    max-height: 300px;
    margin-left: 100%;
}
div.list_i_slika{
    display: flex;
    flex-direction: row;
}
@media screen and (max-width:1050px){
    .onm{
        margin-left: 50%;
        max-width: 250px;
        max-height: 250px;
    }
  }
 
@media screen and (max-width:550px){
    .onm{
        margin-left: 30%;
        max-width: 250px;
        max-height: 250px;
    }
}
