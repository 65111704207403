
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Roboto', sans-serif;
  --background-image: url('assets/bck.png');
  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #CEECC1;
  --color-footer : #189080;
  --color-blog: #65696d;
  --color-text: #006F61;
  --color-subtext: #FF8A71;
  --nav-color:rgba(165, 235, 186, 0.97);
}

.background-imag{
  opacity: 0.8;
}