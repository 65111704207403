.btPage{
    
    
    
    /* align-items: left; */
    
    
    margin: 0 auto;
}


.bottom-content{
    display: flex;
    justify-content: center;
    background-color:rgba(0,0,0,0.7);
    font-family: var(--font-family);
    bottom: 0;
    top: 100%;
    
}



table{
    color: rgb(206, 236, 233);
    border-spacing: 30px;
}
td{
    border-spacing: 10px;
}

.naslovi{
    
    font-weight: bold;
    font-size: 23px;
}
.naslov{
    border-bottom: 1px solid #189080;
    
    width: 320px;
    padding-bottom: 10px;
    padding-left: 12px;
}
.linkovi{
    transition: all 0.2s ease-in-out;
    word-wrap: break-word;
    text-align: left;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.linkovi:hover{
    color: var(--color-footer);
}
.link{
    text-align: left;
    word-wrap: break-word;
}

.ikonica{
    justify-content: left;
    width: 15px;
    height: 15px;

}
.pom_linkovi{
    width: 200px;
    padding: 0;
    margin: 0;
}
@media screen and (max-width:1050px) {
    .naslov{
        font-size: 18px;
    }
    .linkovi{
        font-size: 12px;
        text-align:justify;
        padding: 0%;
    }
}
@media screen and (max-width:550px) {
    .linkovi{
        font-size:8px;
        text-align:justify;
        padding: 0;
        margin: 0;
    }
    .ikonica{
        width: 8px;
        height: 8px;
    }
    table{
        border-spacing: 18px;
    }
}
