.digitron{
    position: relative;
    top: 10%;
    right: 15%;
    flex-direction: column;
    align-self: center;
    border-radius: 50%;
    box-shadow: #333E44;
    box-sizing: border-box;

}