.pasusi{
    color: #333E44;
    border: 0;
    font-size: 19px;
    font-style: inherit;
    font-weight: 400;
    margin: 0;
    outline: 0;
    padding: 0;
    margin: 0 0 1.2em 0em;
    letter-spacing: 0.3px;
    line-height: 24px;
    width: 480px;
    font-family: var(--font-family);
}