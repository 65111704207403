
.container{
    padding: 0% 5% 0% 20%;
    padding-left: 10%;
    display: flex;
    flex-direction: row;
    justify-items: center;
	padding-bottom:10%;
}
.lista {
    list-style-type:disc;
    padding-left: 30px;
  }
.naslov_os{
    font-size: 4.9rem;
    color: #006F61;
    font-family: var(--font-family);
}
.pasus_1{
    color: #333E44;
    border: 0;
    font-size: 19px;
    font-style: inherit;
    font-weight: 400;
    margin: 0;
    margin-bottom: 0px;
    outline: 0;
    overflow: hidden; /* Hides the overflow */
    text-overflow: ellipsis; 
    padding: 0;
    width: 528px;
    font-family: var(--font-family);
    line-height: 24px;
}
.delovi_liste{
    color: #333E44;
    border: 0;
    font-size: 19px;
    font-style: inherit;
    font-weight: 400;
    margin: 0;
    outline: 0;
    padding: 0;
    margin: 0 0 1.2em 3em;
    overflow: hidden; /* Hides the overflow */
    text-overflow: ellipsis; 
    letter-spacing: 0.3px;
    line-height: 24px;
    width: 480px;
    font-family: var(--font-family);
}
.podnaslov_1{
    color: #333E44;
    width: 480px;
    font-size: 18px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.5px;
    font-family: var(--font-family);
    overflow: hidden; /* Hides the overflow */
    text-overflow: ellipsis; 
    
}
.linija{
    border: 0;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
    --divider-border-style: solid;
    --divider-color: #DDDDDD;
    --divider-border-width: 1px;
    padding-bottom: 20px;
}
.podnaslov{
    color: #333E44;
    font-size: 19px;
    font-family: var(--font-family);
}
.linija{
    position: flex;
    flex-direction: row;
    
}

.tekstualni_deo{
    margin-right: 13%;
}
#slika{
    border-radius: 5px;
    left: 100%;
    top: 50%;
    
}
.knjigovodja{
    position: relative;
    top: 18%;
    right: 15%;
    flex-direction: column;
    align-self: center;
    border-radius: 50%;
    box-shadow: #333E44;
    box-sizing: border-box;

}